import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Typography, Button, TextField, Divider, Grid } from '@material-ui/core';
import { MergedCardapio } from 'api/merged';
import { useAtualizarSacola, useObterSacola } from 'storage';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import classNames from 'classnames';
import { useStyles } from './visualizar-produto-modal-styles';
import { isEmpty } from 'lodash';
import { imagemBase64 } from 'utils/tratamento-de-imagem';
import semFoto from 'arquivos-estaticos/global/imagens/semFoto.png';
import { MovSimplesProdutoModel } from 'api/cardapio/models/produto/mov-simples-produto-model';
import { roundTo } from 'utils/round-to';
import { isValidUUIDV4 } from 'utils/is-valid-id';
import { DialogSaurus } from '../dialog-adicionar-produto-com-subitem/dialog-saurus/dialog-saurus';
import { useThemeQueries } from '../dialog-adicionar-produto-com-subitem/util-styles';
import { useParams } from 'react-router-dom';

export interface VisualizarProdutoModalProps {
  aberto: boolean;
  produto: MovSimplesProdutoModel;
  empresaDados: MergedCardapio | undefined;
  quandoForFechado: any;
  mesaId: string
}

export const VisualizarProdutoModal = ({
  aberto,
  produto,
  empresaDados,
  quandoForFechado,
  mesaId
}: VisualizarProdutoModalProps) => {

  const classes = useStyles();
  const [quantidade, setQuantidade] = useState<number>(produto.qCom === 0 ? 1 : produto.qCom);
  const [observacao, setObservacao] = useState<string>(produto.observacao);

  const { empresaId } = useParams<{ empresaId: string }>()
  const sacola = useObterSacola(empresaId)
  const itensSacola = isEmpty(sacola?.data) ? [] : sacola?.data as MovSimplesProdutoModel[]
  const [atualizarSacola] = useAtualizarSacola()
  const { isMobile } = useThemeQueries();

  useEffect(() => {
    setObservacao(produto.observacao)
  }, [produto.observacao])

  const hasItemSacola = itensSacola.find(x => x.id === produto.id)
  const valoresIniciais = useCallback(() => {
    setQuantidade(1);
    setObservacao('');
  }, []);

  // quando fechar
  const fechar = useCallback(() => {
    valoresIniciais();
    quandoForFechado();
  }, [quandoForFechado, valoresIniciais]);

  const addOrEditSacola = useCallback(() => {
    let prod = produto

    prod.qCom = quantidade
    prod.observacao = observacao
    prod.vFinal = roundTo(prod.vUnCom * quantidade)
    atualizarSacola({ produto: prod, empresaId: empresaId })
    quandoForFechado();
  }, [atualizarSacola, observacao, produto, quandoForFechado, quantidade, empresaId]);

  const handleChangeQuantidade = useCallback(
    (operacao) => {
      switch (operacao) {
        case '+':
          setQuantidade(prev => prev += 1);
          break;

        case '-':
          setQuantidade(prev => prev -= 1);
          break;
      }
    },
    [],
  );
  const handleChangeObservacao = useCallback((event) => {
    setObservacao(event.target.value);
  }, []);

  const total = useCallback(() => {
    return ((produto?.vUnCom || 0) * quantidade).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  }, [produto?.vUnCom, quantidade]);

  const tratamentoImg = useMemo(() => {
    const produtoImgs = JSON.parse(localStorage.getItem('ProdutoImgs')!)
    if (!isEmpty(produto?.imgUrl)) {
      return imagemBase64(produto!.imgUrl)
    }
    const produtoImg = produtoImgs?.find((x: any) => x.produtoId === produto.produtoId)
    if (!isEmpty(produtoImgs) && !isEmpty(produtoImg)) {
      return imagemBase64(produtoImg.imagemUrl)
    }
    return semFoto
  }, [produto])

  const permiteEntregaRetirada = empresaDados?.configuracoes.permiteEntrega || empresaDados?.configuracoes.permiteRetirada

  const clienteEstaNaLoja = useCallback((): boolean => {
    //verificar se tem o mesaId e se é válido
    if (!isEmpty(mesaId) && isValidUUIDV4(mesaId))
      return true;

    return false;
  }, [mesaId]);

  const podeConfirmar = (empresaDados?.empresa?.fone || '').length >= 0 && (clienteEstaNaLoja() || permiteEntregaRetirada)

  return (
    <>
      <DialogSaurus
        tamanho="md"
        aberto={aberto}
        titulo={produto.xProd}
        isButtonTitleClose
        handleClickClose={fechar}
        zIndex="9999"
        fullScreen={isMobile}
      >
        <div className={classes.container}>
          <div className={classes.content}>
            {produto.imgUrl.length > 0 ? (
              <div
                className={classes.imgContainer}
                style={{
                  backgroundImage: `url(${tratamentoImg})`,
                }}
              ></div>
            ) : null}

            <div className={classes.info}>
              <Typography
                variant="h5"
                color="textPrimary"
                style={{ fontWeight: 450, marginTop: '1rem' }}
                gutterBottom
              >
                <div>
                  {produto?.vUnCom.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}

                  <span style={{ fontSize: '.9rem', fontWeight: 700 }}> {produto?.uCom}</span>
                </div>
              </Typography>

              {produto.infAdic && (
                <Grid>
                  <Typography>
                    Descrição:
                  </Typography>

                  <Typography
                    variant='body1'
                    style={{
                      fontSize: '14px',
                      lineHeight: '18px',
                    }}>
                    {produto.infAdic}
                  </Typography>
                </Grid>
              )}

              {podeConfirmar ? (
                <>
                  <TextField
                    className={classes.observacao}
                    InputLabelProps={{ shrink: true }}
                    label="Alguma Observação?"
                    multiline
                    rows={4}
                    inputProps={{ maxLength: 150 }}
                    onChange={handleChangeObservacao}
                    value={observacao}
                    placeholder="Descreva sua observação."
                    variant="outlined"
                  />


                </>
              ) : null}
            </div>
          </div>
          {(empresaDados?.empresa?.fone || '').length >= 0 && (clienteEstaNaLoja() || permiteEntregaRetirada) ? (
            <div className={classes.footerContainer}>
              <Divider style={{ margin: '.5rem 0rem' }} />

              <div className={classes.btnsConfirmar}>
                <div className={classes.seletor}>
                  <RemoveIcon
                    onClick={() => handleChangeQuantidade('-')}
                    className={classNames(classes.remover, classes.btn)}
                  />
                  <Typography variant="h6" color="textPrimary">
                    {quantidade}
                  </Typography>
                  <AddIcon
                    onClick={() => handleChangeQuantidade('+')}
                    className={classNames(classes.adicionar, classes.btn)}
                  />
                </div>

                <Button
                  variant="contained"
                  color="primary"
                  className={classes.btnConfirmar}
                  onClick={() => addOrEditSacola()}
                  size="large"
                >
                  <span className={classes.iconBasket}>
                    {hasItemSacola ? 'Editar' : 'Adicionar'}
                  </span>
                  {total()}
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      </DialogSaurus >
    </>
  );
};