import React, { useCallback, useEffect, useRef, useMemo } from 'react';
import {
    Typography,
    Divider,
    Grid,
    Button,
    useMediaQuery,
    useTheme,
    IconButton,
    Slide,
    Box,
} from '@material-ui/core';
import classNames from 'classnames';
import { MergedCardapio } from 'api/merged';
import { SacolaIcon } from 'utils';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import sacolaVazia from 'arquivos-estaticos/global/imagens/sacolaVazia.svg';
import { useStyles } from './sacola-styles';
import { CardapioStorageKeys, useCardapioStorage } from 'utils/cardapio-storage';
import { isEmpty } from 'lodash';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { OkIcon } from 'utils/Icons/ok-icon';
import { DadosDelivery, EnumOpcoesDelivery, ItemSacola, useObterDadosDelivery, useObterSacola, useRemoverSacola } from 'storage';
import { ItemSacolaCard } from '../../item-sacola-card';
import { isValidUUIDV4 } from 'utils/is-valid-id';
import { EnumFasesSacola } from '../../../cardapio-sacola';
import { usePedidos } from 'componentes/providers/pedidos-provider';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { ContatoModel, PessoaModel } from 'api/cardapio/models/pessoa/pessoa-model';
import { guidEmpty } from 'utils/guid-empty';
import { useConfirm } from 'material-ui-confirm';
import { ConfigEmpresaModel } from 'api/cardapio/models/empresa/empresa-model';
import { EnumTpProduto, MovSimplesProdutoModel } from 'api/cardapio/models/produto/mov-simples-produto-model';
import { EnumTipoContato } from 'api/cardapio/models/enums/enum-tipo-contato';
import { OpcoesDelivery } from '../../opcoes-delivery';

export interface SacolaProps {
    empresaDados: MergedCardapio | undefined;
    empresaId: string;
    mesaId: string;
    sacolaAberta: boolean;
    quandoFechado: () => any;
    abrirModalCliente: () => void;
    fazerPedidoWhatsApp: (dadosDelivery?: DadosDelivery) => void;
    abrirModalDelivery: () => void;
    editarItem: (item: MovSimplesProdutoModel) => void;
    faseSacola: EnumFasesSacola;
    entrarFases: (fase: EnumFasesSacola) => void;
    handlePedidoFluxo: () => void;
    configEmp: ConfigEmpresaModel[];
}

export const Sacola = ({
    empresaDados,
    empresaId,
    mesaId,
    quandoFechado,
    sacolaAberta,
    fazerPedidoWhatsApp,
    abrirModalDelivery,
    editarItem,
    faseSacola,
    entrarFases,
    handlePedidoFluxo,
    configEmp
}: SacolaProps) => {

    const classes = useStyles()
    const { getRegistro } = useCardapioStorage()
    const deliveryDados = useObterDadosDelivery({ empresaId: empresaId });
    const dadosEntregaPedido = useRef<DadosDelivery | undefined>(undefined);
    const { pedidoAtual, getPedido, cliente, setCliente, codComanda } = usePedidos()
    const theme = useTheme()
    const [removerSacola] = useRemoverSacola();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const confirm = useConfirm()
    const nextOrBack = useRef<0 | 1>(0)

    const clientePadrao = '99999999000191'

    const clienteEstaNaLoja = useCallback((): boolean => {
        //verificar se tem o mesaId e se é válido
        if (!isEmpty(mesaId) && isValidUUIDV4(mesaId))
            return true;

        return false;
    }, [mesaId]);

    //é bixo, isso aqui estamos usando ref pq o state não atualiza a tempo de trocar(caso altere o endereço/retirada) quando clica em fazer pedido,
    //aí quando editava, sempre passava o antes de editar, usando ref resolveu
    useEffect(() => {
        dadosEntregaPedido.current = deliveryDados.data;
    }, [deliveryDados]);

    const itens = useObterSacola(empresaId);

    useEffect(() => {
        if (sacolaAberta && mesaId) {
            if (empresaDados?.configuracoes.tipoUsoCardapio === 2) {
                getPedido();
            }
        }
    }, [empresaDados?.configuracoes.tipoUsoCardapio, getPedido, mesaId, sacolaAberta])

    const calcularCorSacola = useCallback(() => {
        return `grayscale(1) contrast(1.1) opacity(.65) drop-shadow(0 0 0 ${theme.palette.primary.light})`;
    }, [theme.palette.primary.light]);

    useEffect(() => {
        document.body.classList.toggle(classes.scrollPage);
    }, [classes.scrollPage, sacolaAberta]);

    const produtosVendaAdicionais =
        itens?.data?.filter(
            (produto) => produto.pedidoId === ''
        ) ?? [];

    const agruparProdutos = () => {
        const produtosAgrupados: MovSimplesProdutoModel[] = produtosVendaAdicionais.filter(
            (p) =>
                !p.produtoPaiId &&
                (p.tpProduto === EnumTpProduto.ProdutoComSubItem ||
                    p.tpProduto === EnumTpProduto.Produto ||
                    p.tpProduto === EnumTpProduto.Combo ||
                    p.tpProduto === EnumTpProduto.Medicamento)
        );

        return produtosAgrupados.map((prodAgrupado) => {
            const prodAdicional = produtosVendaAdicionais.filter(
                (adicional) =>
                    adicional.idGroup === prodAgrupado.id &&
                    adicional.tpProduto !== EnumTpProduto.Combo &&
                    adicional.tpProduto !== EnumTpProduto.ProdutoComSubItem &&
                    adicional.tpProduto !== EnumTpProduto.Produto &&
                    adicional.tpProduto !== EnumTpProduto.Medicamento
            );
            if (prodAdicional.length > 0) {
                return {
                    ...prodAgrupado,
                    adicionais: prodAdicional,
                };
            }

            return prodAgrupado;
        });
    };

    const total = useCallback(() => {
        let total =
            (itens?.data || ([] as MovSimplesProdutoModel[])).length > 0
                ? itens?.data?.filter(x => x.indFin).map((item) => {

                    if (item.tpProduto === EnumTpProduto.Combo) {
                        return 0;
                    }
                    if (item.vUnCom === 0)
                        return 0;

                    return item.vUnCom * item.qCom + item.vAcrescUsuario - item.vDescUsuario;
                }).reduce((prev, next) => prev + next, 0)
                : 0;

        return (total || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }, [itens]);

    const totalItens = useCallback(() => {
        let total =
            (itens?.data || ([] as ItemSacola[])).length > 0
                ? itens?.data?.filter(x => !x.produtoPaiId).map((item) => item.qCom).reduce((prev, next) => prev! + next!, 0)
                : 0;

        return total || 0;
    }, [itens]);

    const proximo = useCallback(() => {
        nextOrBack.current = 1

        setTimeout(() => {
            entrarFases(EnumFasesSacola.IdentificarCliente)
        }, 50)

        nextOrBack.current = 0
    }, [entrarFases])

    const colorSacola = useMemo(() => {
        return theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.text.primary;
    }, [theme.palette.primary.main, theme.palette.text.primary, theme.palette.type]);

    const removerItem = useCallback(
        (id: string) => {

            removerSacola({ idProduto: id, empresaId: empresaId });
        },
        [empresaId, removerSacola],
    );

    const fluxoPedidoWhatsapp = useCallback(() => {
        if (
            deliveryDados.data === undefined ||
            (deliveryDados.data.tipo === EnumOpcoesDelivery.Entrega &&
                empresaDados?.configuracoes?.permiteEntrega === false) ||
            (deliveryDados.data.tipo === EnumOpcoesDelivery.Retirada &&
                empresaDados?.configuracoes?.permiteRetirada === false)
        ) {
            abrirModalDelivery();
        } else {
            fazerPedidoWhatsApp()
        }
    }, [abrirModalDelivery, deliveryDados.data, empresaDados?.configuracoes?.permiteEntrega, empresaDados?.configuracoes?.permiteRetirada, fazerPedidoWhatsApp])

    let mesaDados = getRegistro(CardapioStorageKeys.MesaAtual, false);

    const temClientePedido = useCallback(() => {
        const cliente = pedidoAtual?.cliente
        const clientePadrao = configEmp.find(x => x.codigo === 326)?.valor || undefined
        if (cliente?.referenceId === clientePadrao) {
            return false
        }
        const nome = !isEmpty(cliente?.razaoSocial)
        const cpf = !isEmpty(cliente?.cpfCnpj)
        const telefone = !isEmpty(cliente?.telefone)
        const email = !isEmpty(cliente?.email)
        const id = !isEmpty(cliente?.referenceId)

        return (email || cpf || telefone || nome) && id
    }, [configEmp, pedidoAtual?.cliente])

    const description = useMemo(() => {
        const clientePedido = pedidoAtual?.cliente
        return (
            <div>
                {pedidoAtual?.cliente.cpfCnpj === clientePadrao ? (
                    <>
                        <Typography>
                            Voce ainda nao foi identificado neste pedido.
                        </Typography>
                    </>
                ) : (
                    <>
                        <Typography>
                            Verificamos que tem um cliente armazenado de um último pedido, confira os dados e verifique se é você.
                        </Typography>
                        <Box mt={2} />
                        {clientePedido?.razaoSocial && (
                            <Typography variant='body1'>
                                Nome: {clientePedido?.razaoSocial ?? ''}
                            </Typography>
                        )}
                        {clientePedido?.cpfCnpj && (
                            <Typography variant='body1'>
                                CPF: {formatarCPFCNPJ(clientePedido?.cpfCnpj ?? '')}
                            </Typography>
                        )}
                        {clientePedido?.telefone && (
                            <Typography variant='body1'>
                                Telefone: {clientePedido?.telefone ?? ''}
                            </Typography>
                        )}
                        {clientePedido?.email && (
                            <Typography variant='body1'>
                                E-mail: {clientePedido?.email ?? ''}
                            </Typography>
                        )}
                    </>
                )}
            </div >
        )
    }, [pedidoAtual?.cliente])

    const contatos = useMemo(() => {
        let contatosArray: ContatoModel[] = []
        const telefone = new ContatoModel(
            guidEmpty(),
            guidEmpty(),
            EnumTipoContato.TELEFONE,
            pedidoAtual?.cliente?.telefone.replace(/\D/g, "")
        )
        const email = new ContatoModel(
            guidEmpty(),
            guidEmpty(),
            EnumTipoContato.EMAIL,
            pedidoAtual?.cliente?.email
        )
        if (!isEmpty(pedidoAtual?.cliente.telefone)) {
            contatosArray.push(telefone)
        }
        if (!isEmpty(pedidoAtual?.cliente.email)) {
            contatosArray.push(email)
        }

        return contatosArray
    }, [pedidoAtual?.cliente?.email, pedidoAtual?.cliente?.telefone])

    const handleFinalizar = useCallback(() => {
        if (temClientePedido()) {
            confirm({
                title: pedidoAtual?.cliente.cpfCnpj === clientePadrao ?
                    "Cliente Não Idetificado" : "Cliente Identificado",
                description: description,
                confirmationText: pedidoAtual?.cliente.cpfCnpj === clientePadrao ?
                    'Não Identificar' : 'Sou eu, continuar',
                confirmationButtonProps: {
                    variant: "contained",
                    color: 'primary'
                },
                cancellationButtonProps: {
                    variant: 'text',
                    color: 'inherit'
                },
                cancellationText: pedidoAtual?.cliente.cpfCnpj === clientePadrao ?
                    'Me Identificar' : 'Não sou eu, me identificar'
            })
                .then(async () => {
                    if ((pedidoAtual?.cliente.referenceId ?? '') !== cliente?.id) {
                        setCliente({
                            ...new PessoaModel(),
                            contatos: contatos,
                            nome: pedidoAtual?.cliente.razaoSocial ?? '',
                            id: pedidoAtual?.cliente.referenceId ?? '',
                            cpfcnpj: pedidoAtual?.cliente.cpfCnpj ?? ''
                        })
                    }
                    handlePedidoFluxo()
                })
                .catch(() => {
                    proximo()
                })
            return
        }
        proximo()
    }, [
        cliente?.id,
        confirm,
        contatos,
        description,
        handlePedidoFluxo,
        pedidoAtual?.cliente.cpfCnpj,
        pedidoAtual?.cliente.razaoSocial,
        pedidoAtual?.cliente.referenceId,
        proximo,
        setCliente,
        temClientePedido
    ])

    const faseAtual = faseSacola === EnumFasesSacola.Sacola

    return (
        <Slide mountOnEnter unmountOnExit direction={nextOrBack.current === 1 ? 'left' : 'right'} in={faseAtual}>
            <Box height={"100%"} width={"100%"} className={classes.boxContainer}>
                <div className={classes.header}>
                    <IconButton
                        onClick={quandoFechado}
                        style={{ marginRight: '-50px', marginBottom: '-4px' }}
                    >
                        <ArrowBackIosIcon
                            style={{ paddingLeft: '7px', fontSize: '32px', color: colorSacola }}
                        />
                    </IconButton>

                    <SacolaIcon tipo='INPUT' fill={colorSacola} style={{
                        flex: 1, height: '45px', marginBottom: '5px', marginRight: '80px'
                    }} />
                </div>
                <Divider style={{ marginTop: '1rem' }} />

                {(itens?.data || []).length > 0 ? (
                    <>
                        <div className={classes.scrollable}>
                            {(!isEmpty(mesaId) &&
                                <Typography color="textPrimary" className={classes.mesa}>
                                    Mesa: {mesaDados?.codigo} {!isEmpty(codComanda) ? `| Comanda: ${codComanda}` : ''}
                                </Typography>)}
                            {agruparProdutos().map((item, index) => {
                                return (
                                    <ItemSacolaCard
                                        key={index}
                                        item={item}
                                        quandoEditar={editarItem}
                                        quandoRemover={removerItem}
                                    />
                                );
                            })}
                        </div>

                        <div className={classes.footer}>
                            <OpcoesDelivery
                                deliveryDados={deliveryDados.data}
                                empresa={empresaDados?.empresa!}
                                quandoClicar={abrirModalDelivery}
                                permiteEntrega={empresaDados?.configuracoes.permiteEntrega}
                                permiteRetirada={empresaDados?.configuracoes.permiteRetirada}
                                deliveryHabilitado={Boolean(deliveryDados.data)}
                            />
                            <Divider style={{ marginTop: '1rem' }} />
                            <Grid container justify="space-between" style={{ marginTop: '1rem' }}>
                                <Grid item>
                                    <Typography variant="body2" color="textSecondary">
                                        Itens
                                    </Typography>
                                    <Typography variant="h5" color="textPrimary" className={classes.textoTotais}>
                                        {totalItens()}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" color="textSecondary">
                                        Total
                                    </Typography>
                                    <Typography variant="h5" color="textPrimary" className={classes.textoTotais}>
                                        {total()}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container justify="center" className={classes.grdBtns}>
                                <Button
                                    variant="outlined"
                                    size={isMobile ? "medium" : 'large'}
                                    color={theme.palette.type === "light" ? "primary" : "inherit"}
                                    className={classes.btnFooter}
                                    onClick={quandoFechado}
                                >
                                    <SacolaIcon tipo={theme.palette.type === "light" ? 'BUTTON' : 'BUTTON_PRIMARY'} />
                                    Continuar comprando
                                </Button>
                                <Button
                                    variant="contained"
                                    size="large"
                                    className={classNames(classes.buttonPedir, classes.btnFooter)}
                                    startIcon={clienteEstaNaLoja() ? <OkIcon tipo="BUTTON_PRIMARY" /> : <WhatsAppIcon />}
                                    onClick={
                                        clienteEstaNaLoja()
                                            ? () => handleFinalizar()
                                            : fluxoPedidoWhatsapp
                                    }

                                >
                                    {clienteEstaNaLoja() ? "Finalizar pedido" : "Fazer pedido no WhatsApp"}
                                </Button>
                            </Grid>
                        </div >
                    </>
                ) : (
                    <div className={classes.sacolaVazia}>
                        <img
                            src={sacolaVazia}
                            className={classes.imgSacolaVazia}
                            alt="sacola vazia"
                            style={{ filter: calcularCorSacola() }}
                        ></img>
                        <Typography variant="h4" color="textPrimary" className={classes.textoTotais}>
                            A sacola está vazia!
                        </Typography>
                    </div>
                )}
            </Box>
        </Slide >
    )
}